import { Injectable } from '@angular/core';
import { concat, map, Observable, of, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { CalendarEvent, HomePageBanner } from '../models/events.model';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import auLocale from 'dayjs/locale/en-au'
import isBetween from 'dayjs/plugin/isBetween';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CategoryCard } from 'src/app/shared/components/category-card/category-card.component';

dayjs.extend(weekOfYear);
dayjs.extend(isBetween)
dayjs.locale({ ...auLocale });

@Injectable({
  providedIn: 'root',

})
export class HomePageService {

  private weekNumber: number;

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) {
    this.weekNumber = dayjs().week();
  }

  resetCurrentWeek() {
    this.weekNumber = dayjs().week();
  }

  getCurrentWeek() {
    return this.weekNumber;
  }

  getNextWeek(currentWeek: number) {
    return dayjs().week(currentWeek).add(1, 'week').week();
  }

  getTodaysWeek() {
    return dayjs().week();
  }

  getThisWeeksEvents() {
    let from = dayjs().week(this.weekNumber).startOf('week').format('MM/DD')
    let to = dayjs().week(this.weekNumber).endOf('week').format('MM/DD');

    // around new year this may happen from: 12/30, to: 01/05. We have to do 2 calls
     if (to < from) {
      to = dayjs().endOf('year').format('MM/DD');
      const first$ = this.getEventsV2({ from, to }).pipe(map(() => [this.weekNumber]));

      // now get remainer of events that happened in the other part of the week
      from = dayjs().startOf('year').format('MM/DD');
      to = dayjs().week(this.weekNumber).endOf('week').format('MM/DD');
      const rest$ =  this.getEventsV2({ from, to }).pipe(map(() => [this.weekNumber]));
      // execute the observables in the right order
      return concat(first$, rest$).pipe(map(() => [this.weekNumber]));
    } else {
      return this.getEventsV2({ from, to }).pipe(map(() => [this.weekNumber]))
    }
  }

  loadMoreEvents() {
    this.weekNumber = this.weekNumber + 1;

    // only fetch more events from contentful if we don't already have the events for the requested week number
    const events = this.appState.get<CalendarEvent[]>('calendarEvents');
    const weekEvents = events.filter(event => event.week === this.weekNumber);
    if (!weekEvents.length) {
      return this.getThisWeeksEvents();
    } else {
      // emit a new value so subscribers respond
      this.appState.set('calendarEvents', this.appState.get<CalendarEvent[]>('calendarEvents'));
      return of([this.weekNumber]);
    }
  }

  getHomePageBanners() {
    const statement = `
      query getHomePageBanners {
        getHomePageBanners {
          startDate
          endDate
          hide
          image
          link
        }
      }
    `;
    return this.apiService
      .graphql<HomePageBanner[]>({ statement, variables: {}, type: 'getHomePageBanners'})
      .pipe(
        map(banners => {
          banners = banners.map(banner => {
            const startMonth = dayjs(banner.startDate).month();
            const endMonth = dayjs(banner.endDate).month();
            const startYear = dayjs().year();
            const endYear = endMonth < startMonth ? startYear+1 : startYear;
            banner.startDate = dayjs(banner.startDate).set('year', startYear).format('YYYY-MM-DD');
            banner.endDate = dayjs(banner.endDate).set('year', endYear).format('YYYY-MM-DD');
            return banner;
          })
          return banners;
        }),
        map(banners => {
          const res = banners
            .filter(val => !val.hide)
            .filter(banner => dayjs().isBetween(banner.startDate, banner.endDate));
          this.appState.setState('homePageBanners', res);
          return res;
        }),
      )
  }

  getEventsV2(input: { from: string, to: string }): Observable<any> {
    const statement = `
      query getCalendarEventsV2($input: GetCalendarEventInput!) {
        getCalendarEventsV2(input: $input) {
          title
          description
          day
          month
          date
          image
          links {
            title
            type
            mediaId
            eventMedia {
              ... on MusicItem {
                id
              }
              ... on VideoItem {
                id
              }
            }
            mediaTitle
          }
        }
      }
    `;
    return this.apiService
      .graphql<CalendarEvent[]>({ statement, variables: { input }, type: 'getCalendarEventsV2'})
      .pipe(
        tap(newEvents => {
          newEvents = newEvents.map(event => ({
            ...event,
            week: dayjs(`${dayjs().year()}/${event.date}`).week(),
            // filter out events that are linked to a removed Media item
            links: event.links.filter(link => link.eventMedia)
          }))
          const currentEvents = this.appState.get<CalendarEvent[]>('calendarEvents') || [];
          // update events in appstate by appending the new received events to the current collection
          this.appState.setState('calendarEvents', [...currentEvents, ...newEvents]);
        }),
      )
  }

  getHomeCards(): Observable<CategoryCard[]> {

    const statement = `
      query getHomePanels {
        getHomePanels {
          name
          order
          icon
          color
          text
          permissions
          links {
            title
            link
          }
        }
      }
    `;
    return this.apiService
      .graphql<CategoryCard[]>({ statement, variables: {}, type: 'getHomePanels'})
      .pipe(
        map(cards => {
          return cards.map(card => ({
            ...card,
            textColor: card.textColor || card.color,
            iconColor: card.iconColor || card.color,
            buttonColor: card.buttonColor || card.color
          }))
        }),
        tap(cards => this.appState.setState('homePageCards', cards))
      )
  }
}
